import { Controller } from 'stimulus'
import 'classlist-polyfill'

export default class extends Controller {
  static targets = ['searchButton', 'menuButton', 'closeButton', 'menu', 'search']

  toggleSearch() {
    this.hideSearchAndMenuButtons()
    this.closeButtonTarget.classList.remove('hidden')
    this.searchTarget.classList.remove('hidden')
  }

  toggleMenu() {
    this.hideSearchAndMenuButtons()
    this.closeButtonTarget.classList.remove('hidden')
    this.menuTarget.classList.remove('hidden')
  }

  toggleClose() {
    this.showSearchAndMenuButtons()
    this.closeButtonTarget.classList.add('hidden')
    this.menuTarget.classList.add('hidden')
    this.searchTarget.classList.add('hidden')
  }

  showSearchAndMenuButtons() {
    this.searchButtonTarget.classList.remove('hidden')
    this.menuButtonTarget.classList.remove('hidden')
  }

  hideSearchAndMenuButtons() {
    this.searchButtonTarget.classList.add('hidden')
    this.menuButtonTarget.classList.add('hidden')
  }
}
