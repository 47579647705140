import { Controller } from 'stimulus'
import 'classlist-polyfill'

export default class extends Controller {
  static targets = ['panel', 'panelContent', 'open', 'close']

  toggleAccordionActive(event) {
    const ele = event.currentTarget
    const panel = ele.closest('[data-accordion-item]')
    const open = ele.querySelector('[data-accordion-open]')
    const close = ele.querySelector('[data-accordion-close]')
    const panelActive = panel.getAttribute('data-accordion-active')

    if (this.allowMultiple === 'false') {
      for (const panel of this.panelTargets) {
        panel.setAttribute('data-accordion-active', false)
      }
    }

    if (panelActive == 'false') {
      panel.setAttribute('data-accordion-active', true)
      console.log(ele)
      open.classList.add('hidden')
      close.classList.remove('hidden')
    } else {
      panel.setAttribute('data-accordion-active', false)
      open.classList.remove('hidden')
      close.classList.add('hidden')
    }
  }

  get allowMultiple() {
    return this.data.get('allow-multiple')
  }
}
