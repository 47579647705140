import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import Turbolinks from 'turbolinks'
import '@stimulus/polyfills'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Glide from '@glidejs/glide'

import '../css/application.scss'
require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener('turbolinks:load', function () {
  const REF = (name) => document.querySelector(`[data-ref=${name}]`)

  const homepageBannerNewsSlider = new Glide("[data-ref='homeBannerNewsSlider']", {
    type: 'carousel',
    gap: 0,
    autoplay: 6000,
  })

  if (REF('homeBannerNewsSlider')) homepageBannerNewsSlider.mount()

  const sideNewsSlider = new Glide("[data-ref='sideNewsSlider']", {
    type: 'carousel',
  })

  if (REF('sideNewsSlider')) sideNewsSlider.mount()

  const tweetsDesktopSlider = new Glide("[data-ref='tweetsDesktopSlider']", {
    type: 'carousel',
    autoplay: 5000,
    gap: 30,
  })

  if (REF('tweetsDesktopSlider')) tweetsDesktopSlider.mount()

  const tweetsMobileSlider = new Glide("[data-ref='tweetsMobileSlider']", {
    type: 'carousel',
    autoplay: 5000,
    gap: 30,
    breakpoints: {
      1024: {
        perView: 2,
      },
      640: {
        perView: 1,
      },
    },
  })

  if (REF('tweetsMobileSlider')) tweetsMobileSlider.mount()

  let links = document.links

  for (let i = 0, linksLength = links.length; i < linksLength; i++) {
    if (
      links[i].hostname !== window.location.hostname ||
      links[i].href.includes('active_storage')
    ) {
      links[i].target = '_blank'
      links[i].rel = 'noreferrer noopener'
    }
  }
})
